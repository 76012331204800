/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React from 'react';
import { SwiperSlide, SwiperSlideProps } from 'swiper/react';

export interface CarouselSlideProps extends SwiperSlideProps {
  className?: string;
}

const slideStyles = {
  display: 'flex',
  justifyContent: 'center'
  // alignItems: 'center',
};

export const CarouselSlide: React.FC<CarouselSlideProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <SwiperSlide style={slideStyles} className={className} {...rest}>
      {children}
    </SwiperSlide>
  );
};

//  way to turn your component into a SwiperSlide would be to set its displayName to include the string 'SwiperSlider',

CarouselSlide.displayName = 'SwiperSlider';

export default CarouselSlide;
