import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

/**
 * Custom hook that appends a script tag to the document head
 * and removes it on unmount.
 * @param url The URL of the script to append.
 * @param query An optional query string to append to the URL.
 */
export function useScript(url: string, query?: string) {
  const router = useRouter();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [router, url, query]);
}

export default useScript;
